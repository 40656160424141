import React from 'react'

const Page = ({ children, className }) => {
	className = className ? 'Page ' + className : 'Page'
	return <div className={className}>{children}</div>
}

export const Back = () => {
	return (
		<a href='/'>
			<span className='back'>➜</span> Back
		</a>
	)
}

export default Page
