import React from 'react'
import Page, { Back } from './Page'

const Info = () => {
	return (
		<Page className='text'>
			<h1>What is this?</h1>
			<p>
				This is an app to help friends & family share bills and pay for
				things as a group.
			</p>
			<p>Days out, parties, holidays, presents… even a gas bill.</p>
			<p>FuckYouPayMe will make it all easier.</p>
			<p className='small'>
				Please contact{' '}
				<a href='mailto:sambeau@mac.com'>sambeau@mac.com</a> if you
				would like an invite.
			</p>
			<p>
				<Back />
			</p>
		</Page>
	)
}

export default Info
