// submit.js
import React from 'react'

import './form.scss'

const Submit = ({ className, title, size }) => {
	let classes = 'Submit Button'
	classes = className ? ' ' + className : classes
	classes = size ? size + ' ' + classes : classes

	return (
		<div className={classes}>
			<input type='submit' value={title} />
		</div>
	)
}
export default Submit
