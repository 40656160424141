import React, { useState, useEffect } from 'react'

import firebase, { actionCodeSettings } from '../config/firebase'
import 'firebase/auth'

import Page, { Back } from './Page'

import Spinner from '../components/spinner/spinner'
import Form from '../components/forms/form'
import EmailInput from '../components/forms/email'
import SubmitButton from '../components/forms/submit'

import { ReactComponent as Logo } from '../logos/FaceLogo.svg'
import { ReactComponent as MoneyBubble } from '../logos/MoneyBubble.svg'

const Home = () => {
	const [isSignedIn, setIsSignedIn] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isSendingEmail, setIsSendingEmail] = useState(false)
	const [user, setUser] = useState({})
	const [values, setValues] = useState({ email: '' }) // you need this inital state
	const [authError, setAuthError] = useState('')

	useEffect(() => {
		const unregisterAuthObserver = firebase.auth().onAuthStateChanged(u => {
			setIsSignedIn(!!u)
			if (u != null) {
				setUser({
					name: u.displayName,
					email: u.email,
					photoUrl: u.photoURL,
					emailVerified: u.emailVerified,
					uid: u.uid,
				})
			}
			setIsLoading(false)
		})

		return unregisterAuthObserver
	}, [])
	const callback = () => {
		console.log('callback', values)

		if (values.email === '') return //TODO: show error
		setIsSendingEmail(true)
		firebase
			.auth()
			.sendSignInLinkToEmail(values.email, actionCodeSettings)
			.then(function() {
				// The link was successfully sent. Inform the user.
				// Save the email locally so you don't need to ask the user for it again
				// if they open the link on the same device.
				window.localStorage.setItem('emailForSignIn', values.email)
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log('Home:', error.code)
				setAuthError('Error:', error.code) // TODO: this will be obscure error, make nice
			})
	}

	console.log('isSignedIn:', isSignedIn)
	console.log('user:', user)

	if (authError)
		return (
			<Page>
				<b>Oops!</b>
				<br />
				{authError}
				<br />
				<br />
				<Back />
			</Page>
		)

	if (isLoading) {
		return <Spinner />
	}

	if (isSendingEmail) {
		return (
			<Page>
				We've sent a sign-in link to
				<br />
				<b>{values.email}</b>
				<br />
				<br />
				<Back />
			</Page>
		)
	}

	if (!isSignedIn) {
		return (
			<div className='Home'>
				<Logo className='Logo' />
				<MoneyBubble className='MoneyBubble' />
				<h1 className='fypm'>
					Fuck<em>You</em>Pay<em>Me!</em>
				</h1>
				<div className='strapline'>
					Bill-sharing for friends & family
				</div>
				<div className='signIn'>Sign in</div>
				<Form
					className='signInBox'
					callback={callback}
					values={values}
					setValues={setValues}
				>
					{handleChange => (
						<>
							<EmailInput
								placeholder='Email'
								handleChange={handleChange}
								name='email'
								value={values.email}
							/>
							<SubmitButton title='Send a link' />
						</>
					)}
				</Form>
				<div className='info'>
					<a href='/info'>What is this?</a>
				</div>
			</div>
		)
	}

	if (user && !user.name) return <p>Please enter a name…</p>
	return (
		<div className='Home'>
			<h1 className='fypm'>
				Fuck<em>You</em>Pay<em>Me!</em>
			</h1>
			<p>Welcome {user && user.name}!</p>
			<a href='/' onClick={() => firebase.auth().signOut()}>
				Sign-out
			</a>
		</div>
	)
}

export default Home

// import TextInput from '../components/forms/text'
// const AddName = () => {
// 	u.updateProfile({
// 		displayName: 'Big Nose',
// 	})
// 		.then(function () {
// 			// Update successful.
// 			setUser({
// 				...user,
// 				name: firebase.auth().currentUser.displayName,
// 			})
// 		})
// 		.catch(function (error) {
// 			// An error happened.
// 		})
// }
