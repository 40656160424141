import React, { useState, useEffect } from 'react'
import firebase from '../config/firebase'
import 'firebase/auth'

import Page, { Back } from './Page'

import Spinner from '../components/spinner/spinner'

const Login = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [authError, setAuthError] = useState('')

	useEffect(() => {
		// Confirm the link is a sign-in with email link.
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			var email = window.localStorage.getItem('emailForSignIn')
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				email = window.prompt(
					'Please provide your email for confirmation'
				)
			}
			// The client SDK will parse the code from the link for you.
			firebase
				.auth()
				.signInWithEmailLink(email, window.location.href)
				.then(function(result) {
					// Clear email from storage.
					window.localStorage.removeItem('emailForSignIn')
					// You can access the new user via result.user
					// Additional user info profile not available via:
					// result.additionalUserInfo.profile == null
					// You can check if the user is new or existing:
					// result.additionalUserInfo.isNewUser
					console.log(result.user)
					console.log(result.additionalUserInfo.isNewUser)
					setIsLoading(false)
				})
				.catch(function(error) {
					// Some error occurred, you can inspect the code: error.code
					// Common errors could be invalid email and invalid or expired OTPs.
					//auth/invalid-action-code
					//"auth/invalid-email"
					//auth/invalid-action-code"
					console.log('Login:', error.code)
					if (error.code === 'auth/invalid-email')
						setAuthError('Invalid email address')
					else if (error.code === 'auth/invalid-action-code')
						setAuthError(
							'This email link has expired (or maybe you mistyped your email address), please try again'
						)
					else setAuthError('Error: ' + error.code)
				})
		}
	}, [])

	if (authError)
		return (
			<Page>
				<b>Oops!</b>
				<br />
				{authError}
				<br />
				<br />
				<Back />
			</Page>
		)

	if (isLoading) {
		return <Spinner />
	}
	window.location.href = '/'
}

export default Login
