import * as firebase from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyCEiw6TT0n1NL4gUo5h2sWgLBjXmLC8UwQ',
	authDomain: 'fuck-you-pay-me.firebaseapp.com',
	databaseURL: 'https://fuck-you-pay-me.firebaseio.com',
	projectId: 'fuck-you-pay-me',
	storageBucket: 'fuck-you-pay-me.appspot.com',
	messagingSenderId: '1032269005152',
	appId: '1:1032269005152:web:3d9a1b8126b24525c61e05',
	measurementId: 'G-HNRFERK2FT',
}

export const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be whitelisted in the Firebase Console.
	url: 'https://fuckyoupayme.org/login',
	// This must be true.
	handleCodeInApp: true,
}
// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig)

export default fire
