import React from 'react'

export const useForm = (callback, values, setValues) => {
	const handleSubmit = event => {
		if (event) event.preventDefault()
		callback(values)
	}

	const handleChange = event => {
		event.persist()
		setValues(values => ({
			...values,
			[event.target.name]: event.target.value,
		}))
	}

	return {
		handleChange,
		handleSubmit,
	}
}

const Form = props => {
	const { handleChange, handleSubmit } = useForm(
		props.callback,
		props.values,
		props.setValues
	)

	return (
		<form onSubmit={handleSubmit} className={props.className}>
			{props.children(handleChange)}
		</form>
	)
}

export default Form
