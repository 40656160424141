import React from 'react'
import { Router } from '@reach/router'

import Home from './Home'
import Login from './Login'
import Info from './Info'

import './App.scss'

const App = () => {
	return (
		<div className='App'>
			<Router>
				<Home path='/' />
				<Login path='login' />
				<Info path='info' />
			</Router>
		</div>
	)
}

export default App
