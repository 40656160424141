// email.js
import React from 'react'

import { ReactComponent as Icon } from './icons/email-light.svg'
import './form.scss'

// /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}
//   [a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

const EmailInput = ({
	value,
	handleChange,
	className,
	pattern,
	name,
	placeholder,
}) => {
	return (
		<div
			className={
				className ? 'Email Input icon' + className : 'Email Input icon'
			}
		>
			<Icon className='email icon' />
			<input
				type='email'
				pattern={pattern ? pattern : '.+@.+..+'}
				placeholder={placeholder ? placeholder : ''}
				autoCorrect='off'
				spellCheck='off'
				value={value}
				name={name ? name : 'email'}
				onChange={handleChange}
				require='true'
			/>
		</div>
	)
}
export default EmailInput
